import React from 'react';
import { Link } from 'gatsby';
import '../../assets/styles/components/quote-nav.scss';
import BackArrow from '../../images/quote/icon-arrow-left.inline.svg';

const ProgressBar = ({ step }) => {
  const getBackLink = () => {

    switch (step) {
      case '2':
        return '/quote/step-one';
      case '3':
        return '/quote/step-two';
      case '4':
        return '/quote/step-three';
      case '5':
        return '/quote/step-four';
      case '6':
        return '/quote/step-five';
      default:
        return '/quote';
    }
  };

  return (
    <div className="quote-nav-wrapper">
      <Link to={getBackLink()} className="arrow-cta hidden-sm"><BackArrow /> Back</Link>
      <span>Step {step} of 6</span>
    </div>
  );
};

export default ProgressBar;
