import React from 'react';
import '../../assets/styles/components/progress-bar.scss';

const ProgressBar = ({step}) => {
  const calculateProgress = () => {
    return (100 * step) / 6;
  };

  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar" style={{ width: `${calculateProgress()}%` }}></div>
    </div>
  );
};

export default ProgressBar;
