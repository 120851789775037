import React, { useEffect, useState, useContext } from 'react';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import { quoteContext } from '../../../provider';

import '../../assets/styles/pages/quote/step-three.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProgressBar from '../../components/quotes/progress-bar';
import QuoteNav from '../../components/quotes/quote-nav';
import BackArrow from '../../images/quote/icon-arrow-left.inline.svg';

/* global window */
const QuoteStepThree = () => {
  const data = useStaticQuery(graphql`
    query {
      allDamagetype {
        edges {
          node {
            damageType_id
            description
          }
        }
      }
    }
  `);
  const options = data.allDamagetype.edges;
  const [damagedCaused, setdamagedCaused] = useState({
    id: '',
    description: ''
  });
  let { quoteForm } = useContext(quoteContext);

  useEffect(() => {
    if (Object.keys(quoteForm).length > 0) {
      setdamagedCaused(quoteForm.damagedCaused);
    } else {
      navigate('/quote');
    }
  }, [quoteForm]);

  const handleInputChange = (event, option) => {
    setdamagedCaused(option);
  };

  const handleSubmit = (event, context) => {
    event.preventDefault();
    context.addToQuoteForm({
      ...quoteForm,
      damagedCaused: damagedCaused
    });
    navigate('/quote/step-four');
  };

  const hasDescription = () => {
    if (typeof damagedCaused !== 'undefined') {
      return (damagedCaused.description.length > 0);
    }

    return false;
  }

  return (
    <Layout wrapperClass="quote-container" hideHeaderFooter={true}>
      <quoteContext.Consumer>
        {context => (
          <React.Fragment>
            <ProgressBar step="3" />
            <SEO title="DingGo | Car Quote - Describe how damage was caused" description="Upload your photos and describe the damage to compare car repair quotes online, fast and free. Snap. Compare. Repair." />
            <div className="quote-wrapper step-three">
              <div className="container xs">
                <QuoteNav step="3" />
                <h1>Type of Damage</h1>
                <p className="intro">
                  How would you best describe how the damaged was caused?
                </p>
                <form onSubmit={(e) => { handleSubmit(e, context); }}>
                  <div className="radio-btn-wrapper">
                    {options.length > 0 && (
                      options.map(({ node }) => (
                        <div className="radio-container" key={node.damageType_id}>
                          <label className="radio-label">{node.description}
                            <input type="radio" onChange={(e) => { handleInputChange(e, node); }} value={node.damageType_id} name="damagedCaused"
                              checked={damagedCaused === node} />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      )))}
                  </div>
                  <div className="form-btn-wrapper">
                    <Link to="/quote/step-two" className="arrow-cta"><BackArrow />Back</Link>
                    <button className={`btn ${hasDescription() ? '' : 'disable'}`} type="submit" id="button-click-to-step-4">Next</button>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        )}
      </quoteContext.Consumer>
    </Layout>
  );
};

export default QuoteStepThree;